export const environment = {
  production: true,
  parseAppId: 'e91ff6f731c7f8fc969826cf66d4219f644b70bc',
  parseServerUrl: 'https://live-ejc-22185.nodechef.com/parse',
  currency: 'GBP',
  currencySymbol: '\u00A3',
  appId: 'mdlpos',
  project: 'ejc',
  version: '3.4.0',
  published: '2025-01-31',
  firebase: {
    apiKey: "AIzaSyD6mGF9ofNsiCJxNn1bvqEwIoxncbfNR-o",
    authDomain: "mdlware-io.firebaseapp.com",
    databaseURL: "https://mdlware-io.firebaseio.com",
    projectId: "mdlware-io",
    storageBucket: "mdlware-io.firebasestorage.app",
    messagingSenderId: "115005790053",
    appId: "1:115005790053:web:f76e8a964d42e1b46fb38c",
    measurementId: "G-4CEJ3T25NE"
  }
};
