import { Component, OnInit } from '@angular/core';
import { CartProvider } from '../../providers/cart.provider';
import { AlertController, ModalController, ActionSheetController, NavController, IonList, IonInput } from '@ionic/angular';
import { ParseProvider } from '../../providers/parse.provider';
import { CartItem } from '../../interfaces/cartItem';
import { ActionSheetButton } from '../../interfaces/actionSheetButton';
import { CurrencyProvider } from '../../providers/currency.provider';
import { PrinterProvider } from '../../providers/printer.provider';
import { DeviceProvider } from '../../providers/device.provider';
import { ErrorProvider } from '../../providers/error.provider';
import { Callbacks } from '../../interfaces/callbacks';
import { UriProvider } from '../../providers/uri.provider';
import { DiscountPage } from '../discount/discount.page';
import { ToastProvider } from '../../providers/toast.provider';
import { ElevationProvider } from '../../providers/elevation.provider';
import { StoreProvider } from '../../providers/store.provider';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.page.html',
    styleUrls: ['./cart.page.scss'],
    standalone: false
})
export class CartPage implements OnInit {

  public smallDevice = false;
  public objectKeys = Object.keys;
  public barcode = '';
  public parseDevice: Parse.Object;
  public customBarcode: boolean;
  public barcodeSuccess = '';
  public clearCartSave: boolean;

  constructor(
    public cartProvider: CartProvider,
    public currencyProvider: CurrencyProvider,
    private alertController: AlertController,
    private modalCtrl: ModalController,
    private sheetCtrl: ActionSheetController,
    private parseProvider: ParseProvider,
    private navCtrl: NavController,
    private printerProvider: PrinterProvider,
    private deviceProvider: DeviceProvider,
    private errorProvider: ErrorProvider,
    private uriProvider: UriProvider,
    private toastProvider: ToastProvider,
    private elevationProvider: ElevationProvider,
    private storeProvider: StoreProvider
  ) {
    this.parseProvider.cart.messages.subscribe(message => {
      if (message.type === 'save') {
        this.refreshCartList();
      }
    });
  }

  ionViewDidEnter() {
    // this.focusInput('ion-input');
  }

  focusInput() {
    const barcodeScanner = document.querySelector('input');
    barcodeScanner.focus();
  }

  async barcodeAdd() {
    let success: boolean;
    const barcode = this.barcode;
    this.barcode = '';
    if (barcode === "") {
      console.log('no barcode entered, skipping');
      return;
    }
    if (this.customBarcode) {
      success = await this.cartProvider.addByBarcode(barcode, 1);
    } else {
      success = await this.cartProvider.addById(barcode, 1);
    }
    this.barcodeSuccessToggle(success);
    this.focusInput();
  }

  barcodeSuccessToggle(
    success: boolean
  ) {
    this.barcodeSuccess = (success) ? 'success' : 'failure';
    setTimeout(() => this.barcodeSuccess = '', 1000);
  }

  async ngOnInit() {
    this.refreshCartList();
    this.setSmallDevice();
    const parseDevice = await this.deviceProvider.getCurrent('cart page :: ngoninit');
    await this.deviceInit(parseDevice);
    this.cartProvider.init(parseDevice);
  }

  /**
   * Check Parse device settings to see which browsing features are true
   */
  async deviceInit(
    parseDevice: Parse.Object
  ) {
    if (parseDevice) {
      this.parseDevice = parseDevice;  
      this.customBarcode = this.parseDevice.get('customBarcode');
      this.clearCartSave = this.parseDevice.get('clearCartSave');
    } else {
      this.toastProvider.temporary('Unable to connect');
    }
  }

  async refreshCartList() {
    await this.parseProvider.cart.getAll();
  }

  async setQtyPopup(
    productId: string,
    qty: number,
    list: IonList
  ) {
    const alert = await this.alertController.create({
      header: 'Set Qty',
      inputs: [
        {
          name: 'newQty',
          type: 'number',
          placeholder: 'Enter new quantity'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        },
        {
          text: 'Ok',
          handler: data => {
            this.integerCheck(productId, data.newQty, list);
          }
        }
      ]
    });
    alert.present();
  }

  isInteger(
    input: number
  ) {
    return input % 1 === 0;
  }

  integerCheck(
    productId: string,
    qty: number,
    list: IonList
  ) {
    const integer = this.isInteger(qty);
    if (integer) {
      this.cartProvider.setQty(productId, qty);
      this.cleanup(list);
    } else {
      this.toastProvider.showToast('Decimal quantities are not valid');
      this.setQtyPopup(productId, qty, list);
    }
  }

  async cleanup(
    list: IonList
  ) {
    const result = list.closeSlidingItems();
    this.elevationProvider.authenticationProvider.userSessionProvider.elevated = false;
  }

  async setCartNamePopup() {
    if (this.cartProvider.parseCart) {
      this.cartProvider.updateParseCart();
    } else {
      const alert = await this.alertController.create({
        header: 'Please enter a name',
        inputs: [
          { name: 'cartName', type: 'text', placeholder: 'Name' }
        ],
        buttons: [
          { text: 'Cancel', role: 'cancel',},
          { text: 'Save', handler: data => { this.saveCart(data.cartName); }}
        ]
      });
      alert.present();
    }
  }

  decrementQty(productId: string) {
    this.cartProvider.decrementQty(productId);
  }

  incrementQty(productId: string) {
    this.cartProvider.incrementQty(productId);
  }

  removeProduct(productId: string) {
    this.cartProvider.removeProduct(productId);
  }

  saveCart(name: string) {
    this.parseProvider.cart.presentLoading('Saving cart')
    .then(() => {
      this.parseProvider.cart.save(name, this.cartProvider.items, this.cartProvider.customer);
      
      if (this.clearCartSave) {
        this.cartProvider.clearCart();
      } else {
        this.cartProvider.unsetCustomer();
      }
    });
  }

  closeCartModal() {
    this.modalCtrl.dismiss();
  }

  getCartLength(): number {
    return Object.keys(this.cartProvider.items).length;
  }

  async clearCart() {
    const alert = await this.alertController.create({
      header: 'Clear Cart',
      message: 'This will remove all items in the cart',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {}
        },
        {
          text: 'Clear',
          handler: () => {
            this.cartProvider.clearCart();
          }
        }
      ]
    });
    alert.present();
  }

  getActionSheetButtons(): ActionSheetButton[] {
    const actionSheetButtons: Array<ActionSheetButton> = [];
    const keys = Object.keys(this.parseProvider.cart.saved);

    keys.forEach(key => {
      if (this.parseProvider.cart.saved.hasOwnProperty(key)) {
        const cart = this.parseProvider.cart.saved[key];
        const data = {
          text: cart[0],
          handler: this.onSavedCartClick.bind(this, key)
        };
        actionSheetButtons.push(data);
      }
    });

    actionSheetButtons.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
      handler: () => {}
    });

    return actionSheetButtons;
  }

  onSavedCartClick(key) {
    const cart = this.parseProvider.cart.saved[key][1];
    this.cartProvider.loadFromObject(cart.get('cartItems'), cart.get('customer'), cart)
      .then(() => {
        // TODO :: make this a setting
        // this.cartProvider.delete(cart);
      });
  }

  async openLoadCartsActionSheet() {
    const actionSheet = await this.sheetCtrl.create({
      translucent: true,
      header: 'Pick a saved cart',
      buttons: this.getActionSheetButtons()
    });
    actionSheet.present();
  }

  getImageUrl(item: CartItem): string {
    return item.image
    ? this.uriProvider.forceHttps(item.image)
    : '';
  }

  convertPrice(input: string) {
    const result: number = parseFloat(input);
    return this.currencyProvider.transform(result);
  }

  getWindowWidth() {
    const windowWidth: number = window.innerWidth;
    return windowWidth;
  }

  // TODO :: this should be on a listener
  setSmallDevice() {
    this.smallDevice = (this.getWindowWidth() < 768);
  }

  navigateTo(route: string) {
    this.navCtrl.navigateRoot(route);
  }

  async checkout() {
    const parseDevice = await this.deviceProvider.getCurrent('cart page :: checkout');
    const callbacks: Callbacks = {
      main: {
        callback: this.checkout.bind(this)
      }
    };
    if (parseDevice) {
      if (this.printerProvider.printer || !parseDevice.get('printerWarning')) {
        this.checkDrawerAssigned();
      } else {
        this.setPrinterAlert();
      }
    } else {
      this.errorProvider.alert(callbacks, 'Unable to connect');
    }
  }

  progressToCheckout() {
    this.closeCartModal();
    this.navigateTo('checkout');
  }

  async setPrinterAlert() {
    const alert = await this.alertController.create({
      header: 'Checkout',
      message: 'This device has no printer set',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {}
        },
        {
          text: 'Continue to Checkout',
          handler: () => {
            this.checkDrawerAssigned();
          }
        },
        {
          text: 'Go to Settings',
          handler: () => {
            this.closeCartModal();
            this.navigateTo('settings/settingsrouter/(settings:printers)');
          }
        }
      ]
    });
    alert.present();
  }

  async checkDrawerAssigned() {
    const device = await this.deviceProvider.getCurrent('cart page :: check drawer');
    let drawer: Parse.Object|false = false;
    if (device) {
      drawer = await device.get('drawer');
    }
    if (!drawer) {
      this.noDrawerAssigned();
    } else {
      this.checkStoreAssigned(device);
    }
  }

  async checkStoreAssigned(
    device: Parse.Object
  ) {
    const validStore = await this.storeProvider.init(device);
    console.log(validStore);
    if (validStore) {
      this.progressToCheckout()
    } else {
      this.noStoreAssigned();
    }
  }

  async noStoreAssigned() {
    const alert = await this.alertController.create({
      header: 'Checkout',
      message: 'There is no valid store assigned to this device',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {}
        },
        {
          text: 'Go to Store settings',
          handler: () => {
            this.closeCartModal();
            this.navigateTo('settings/settingsrouter/(settings:store)');
          }
        }
      ]
    });
    alert.present();
  }

  async noDrawerAssigned() {
    const alert = await this.alertController.create({
      header: 'Checkout',
      message: 'You have not started or joined a trading session',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {}
        },
        {
          text: 'Go to Trading Session settings',
          handler: () => {
            this.closeCartModal();
            this.navigateTo('settings/settingsrouter/(settings:trading)');
          }
        }
      ]
    });
    alert.present();
  }

  async discount(
    item: CartItem,
    list: IonList
  ) {
    const elevated = await this.elevationProvider.elevate();
    if (elevated) {
      this.modalCtrl.create({
        component: DiscountPage,
        componentProps: {
          item: item
        }
      }).then((modal) => {
        modal.onDidDismiss()
          .then(done => this.cleanup(list));
        modal.present();
      });
    }
  }

}
