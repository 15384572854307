import { Injectable } from '@angular/core';
import { ParseProvider } from './parse.provider';
import { CartItemCollection } from '../interfaces/cartItemCollection';
import { CartProvider } from './cart.provider';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderProvider {

  public orders: Parse.Object[];
  public results: Parse.Object[] = [];
  public searchTerms: string[] = [];
  public searchString = '';
  public searchMode = '';
  public location = '';
  public dateFrom = null;
  public dateTo = null;
  public resultsUpdated: Subject<Parse.Object[]> = new Subject();

  constructor(
    private parseProvider: ParseProvider,
    private cartProvider: CartProvider
  ) {
    this.parseProvider.order.resultsUpdated.subscribe(this.onResultsUpdate);
  }

  async get(id: string): Promise<Parse.Object | false> {
    let response: Parse.Object | false = false;

    try {
      response = await this.parseProvider.order.get(id);
    } catch (e) {
      console.log(e);
    }

    return response;
  }

  async getAll(customer?: Parse.Object) {
    const orders: Parse.Object[] = await this.parseProvider.order.getAll(customer);
    this.orders = orders;

    return orders;
  }

  async getPaginatedOrders(
    start: number,
    size: number,
    customer?: Parse.Object,
    location?: string,
    from?: Date,
    to?: Date,
    archive?: boolean
  ): Promise<Parse.Object[]> {
    const orders: Parse.Object[] = await this.parseProvider.order.getPaginatedOrders(start, size, customer, location, from, to, archive);

    return orders;
  }

  // TODO :: further work, deal with clearing old orders if checkout is cancelled but no connection to parse
  async cancel(order: Parse.Object) {
    return await this.parseProvider.order.cancel(order);
  }

  async create(details: any = {}): Promise<Parse.Object | false> {
    let response: Parse.Object | false = false;

    try {
      response = await this.parseProvider.order.create(details);
    } catch (e) {
      console.error(e);
    }

    return response;
  }

  async addItems(orderId: string, items: CartItemCollection): Promise<boolean> {
    let response = false;

    try {
      response = await this.parseProvider.order.addItems(orderId, items);
    } catch (e) {
      console.error(e);
    }

    return response;
  }

  async updateItems(orderId: string, items: CartItemCollection): Promise<boolean> {
    let response = false;

    try {
      response = await this.parseProvider.order.updateItems(orderId, items);
    } catch (e) {
      console.error(e);
    }

    return response;
  }

  /**
   * 
   * @param details 
   * @param drawer 
   * @param voucher 
   */
  async addPayment(
    details: any = {},
    drawer?: Parse.Object,
    voucher?: string
  ): Promise<Parse.Object | false> {
    let response: Parse.Object | false = false;

    try {
      response = await this.parseProvider.order.addPayment(details, drawer, voucher);
    } catch (e) {
      console.error(e);
    }

    return response;
  }

  async complete(order: Parse.Object): Promise<string | false> {
    let response: string | false = false;

    try {
      response = await this.parseProvider.order.complete(order);
    } catch (e) {
      console.log(e);
    }

    return response;
  }

  async setCustomer(
    order: Parse.Object,
    mode: string,
    customer?: Parse.Object
  ): Promise<boolean> {
    let response = false;

    try {
      response = await this.parseProvider.order.setCustomer(order, mode, customer);
    } catch (e) {
      console.log(e);
    }

    return response;
  }

  async getPayments(id: string, archive?: boolean): Promise<Parse.Object[] | false> {
    let response: Parse.Object[] | false = false;

    try {
      response = await this.parseProvider.order.getPayments(id, archive);
    } catch (e) {
      console.log(e);
    }

    return response;
  }

  async getItems(id: string, archive?: boolean): Promise<Parse.Object[] | false> {
    let response: Parse.Object[] | false = false;

    try {
      response = await this.parseProvider.order.getItems(id, archive);
    } catch (e) {
      console.log(e);
    }

    return response;
  }

  async finalise(order: Parse.Object, debug: number): Promise<Parse.Object | false> {
    let response: Parse.Object | false = false;

    try {
      response = await this.parseProvider.order.finalise(order, debug);
    } catch (e) {
      console.log(e);
    }

    return response;
  }

  async getExternalId(order: Parse.Object): Promise<Parse.Object | false> {
    let counter = 0;
    let response: Parse.Object | false = false;

    while (!order.get('externalId') && counter < 3) {
      try {
        if (counter > 0) {
          await this.wait(3000); //wait 3 seconds each attempt after the first time
        };

        order = await this.parseProvider.order.getExternalId(order.id);

        if (order.get('externalId')) {
          response = order;
        }
      } catch (e) {
        console.log(e);
      }

      counter++;
    }

    return response;
  }

  wait(ms = 1000) {
    return new Promise(resolve => {
      console.log(`waiting ${ms} ms...`);
      setTimeout(resolve, ms);
    });
  }

  onResultsUpdate = (results) => {
    this.reset();
    this.results = results;
    this.resultsUpdated.next(results);
  }

  getSearchResults(search: string, mode: string, location?: string, from?: Date, to?: Date) {
    if (
      search === this.searchString
      && mode === this.searchMode
      && location === this.location
      && from === this.dateFrom
      && to === this.dateTo
    ) {
      this.resultsUpdated.next(this.results);

      return;
    }

    this.searchString = search;
    this.searchMode = mode;
    this.location = location;
    this.dateFrom = from;
    this.dateTo = to;
    const words = search.split(' ');
    this.searchTerms = words.filter(word => word.length >= 1);
    this.parseProvider.order.search(this.searchTerms, mode, location, from, to);
  }

  reset() {
    this.results = [];
  }
}
